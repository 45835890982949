import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs, { utc } from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { mainApi, s3urls } from '../../helpers/api';
import submit from './submit';
import GenderSelector, { TGender } from '../ui/GenderSelector';
import { Button, DateTimeInput, IPlace, Input, InputContainer, PlaceInput, fromDateTime } from 'src/libs';

export type WIDGET_TYPE = 'default' | 'lablife' | 'partial';
export type PROGNOSETYPE = 'IDP' | 'AP';

dayjs.extend(utcPlugin);

// FIXME: duplicate
export interface IData {
  place: {
    name: string;
    lat: number;
    lon: number;
  } | null;
  name: string;
  date: string | null;
  time: string | null;
  hash?: string;
}

export default function FormDataInput(
  {
    hash,
    updateData,
    prognoseType
  }:
    {
      hash: string;
      updateData(newData: any): void;
      prognoseType: 'AP' | 'IDP';
    }) {
  const [nameMessage, setNameMessage] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [timeMessage, setTimeMessage] = useState<string | undefined>();
  const [placeMessage, setPlaceMessage] = useState<string | undefined>();
  const [name, setName] = useState('');
  const [gender, setGender] = React.useState<TGender>('female');
  const [time, setTime] = useState<string>('12:00:00');
  const [date, setDate] = useState<string | null>('');
  const [place, setPlace] = useState<IPlace | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const isIdp = window.location.pathname.includes('/idp');

  const isDisabled = React.useMemo((): boolean => {
    return Boolean(
      nameMessage || !name ||
      timeMessage || !time ||
      dateMessage || !date ||
      placeMessage || !place
    );
  }, [nameMessage, name, timeMessage, time, dateMessage, date, placeMessage, place]);

  const onSubmit = () => {
    setIsSubmitted(true);
    submit(hash, {
      place,
      name: name.trim(),
      date,
      time,
      gender
    }, prognoseType, updateData);
  }

  const onNameChange = (name: string) => {
    const resultName = name.trim();
    setName(name);
    if (/^[a-z,а-я,ё,\s]*$/i.test(resultName)) {
      setNameMessage(resultName.length === 0 ? 'Имя не может быть пустым' : undefined);
    } else {
      setNameMessage('В имени могут быть только буквы');
    }
  }

  const onDateChange = (isoDate?: string) => {
    const message = 'Нужно ввести дату'
    if (isoDate) {
      const [year, month, day] = isoDate.split('T')[0].split('-');
      const date = `${day}.${month}.${year}`;
      const yearNumber = Number(year);
      setDate(date);
      setDateMessage(yearNumber < 1900 ? 'Введите корректную дату' : yearNumber > dayjs().year() ? 'Введите дату в прошлом' : undefined);
    } else {
      setDateMessage(message);
    }
  }

  const onTimeChange = (isoTime?: string) => {
    const message = 'Нужно ввести время'
    if (isoTime) {
      const time = dayjs(isoTime).utc().format('HH:mm:ss');
      setTime(time);
      setTimeMessage(undefined);
    } else {
      setTimeMessage(message);
    }
  }

  const onPlaceChange = (place: IPlace | string | null) => {
    if (typeof place === 'object') {
      setPlace(place);
      setPlaceMessage(undefined);
    } else {
      setPlace(place => ({ ...place!, lat: 0, lon: 0 }));
      setPlaceMessage('Выберите город из списка');
    }
  }

  const checkErrorMode = (val: any) => Boolean(val) ? 'error' : 'normal';

  return (
    <Container>
      <FormInputText>
        <p>Введите ваши данные, чтобы получить <br /> {isIdp ? 'Индивидуальный план развития' : 'Автопрогноз'}</p>
      </FormInputText>
      <FormContainer>
      <InputContainer label='Имя' message={nameMessage}>
          <StyledInput
            size='small'
            value={name}
            onChange={onNameChange}
            placeholder='Как Вас зовут?'
            mode={checkErrorMode(nameMessage)}
          />
        </InputContainer>
        <InputContainer label='Ваш пол'>
          <GenderSelector value={gender} setValue={setGender} compact />
        </InputContainer>

        <InputContainer label='Дата рождения' message={dateMessage}>
          <StyledDateTimeInput
            size='small'
            type='date'
            value={''}
            onChange={onDateChange}
            onInvalid={onDateChange}
            hideIcon={true}
            inputmode='numeric'
            mode={checkErrorMode(dateMessage)}
          />
        </InputContainer>

        <InputContainer label='Время рождения' message={timeMessage}>
          <StyledDateTimeInput
            size='small'
            type='time'
            value={fromDateTime('01.02.1989', time)}
            inputmode='numeric'
            hideIcon={true}
            onChange={onTimeChange}
            onInvalid={onTimeChange}
            mode={checkErrorMode(timeMessage)}
            utcMode
          />
        </InputContainer>
        <InputContainer label='Место рождения' message={placeMessage}>
          <StyledPlaceInput
            size='small'
            value={place?.name || ''}
            onSelect={onPlaceChange}
            onChange={onPlaceChange}
            placeholder='Начните вводить место рождения'
            asyncDataFn={mainApi.places.bind(mainApi)}
            asyncDetailFn={mainApi.place.bind(mainApi)}
            mode={checkErrorMode(placeMessage)}
            lang="ru"
          />

        </InputContainer>
        <Button
          onClick={onSubmit}
          disabled={isDisabled}
        >Получить {isIdp ? 'план развития' : 'aвтопрогноз'}
        </Button>
        <Footer>
          Нажимая «Получить {isIdp ? 'план развития' : 'aвтопрогноз'}», вы принимаете <br />
          <a href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>
            Пользовательское соглашение
          </a>
        </Footer>
      </FormContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100% !important;
`

const FormContainer = styled.div`
  /* width: 42.5rem; */
  width: 60%;
  /* max-width: 90%; */
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  font-size: 0.875rem;

  & > :first-child {
    grid-column: 1 / 3;
  }

  & > :nth-child(2) {
    grid-column: 1 / 3;
  }

  & > :nth-child(n + 5) {
    grid-column: 1 / 3;
  }
  & > button {
    margin-top: 0.7rem;
    text-align: center;
    color: var(--color-white);
    font-size: 1rem;
    background: var(--color-sea);

    &[disabled] {
      color: var(--color-sea);
      background: var(--color-sea-light);
    }
  }

  @media (max-width: 768px){
    width: 90%;
      & > :nth-child(1) {
      grid-column: 1 / 3;
    }

  & > :nth-child(2) {
    grid-column: 1 / 3;
    }
  }
`;

const TimeInfo = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: var(--main-color);

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.4rem;
    fill: var(--main-color);
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;
  margin-bottom: 4rem;

  & a {
    color: var(--color-sea2);
  }

  @media (max-width: 768px){
    font-size: 0.8rem;
  }
`;

const StyledInput = styled(Input)`
  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledDateTimeInput = styled(DateTimeInput) <{ error?: boolean }>`
  ${props => props.error && css`
    input {
      border-color: var(--red-color);
    }
  `};

  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledPlaceInput = styled(PlaceInput) <{ error?: boolean }>`
    ${props => props.error && css`
    input {
      border-color: var(--red-color);
    }
  `};

    @media (max-width: 768px){
    font-size: 1rem;
  }
`


const FormInputText = styled.div`
  max-width: 90%;
  width: 60%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--color-sea);
  margin-bottom: 1rem;

  & > p {
    margin-top: 0;
    width: 100%;
    font-size: 2rem;
    text-align: left;
  }

  @media (max-width: 768px){
    width: 90%;
  }
`

import { fetchAPI_REST} from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';

import { signOut } from 'src/store/actions/user';
import { getIokaLink } from 'src/helpers/payments';

export const CONFIG_GET_SUCCESS = 'CONFIG_GET_SUCCESS';
export const CONFIG_REMOVE = 'CONFIG_REMOVE';

export interface IProductForConfig {
    formationId: number;
    formationProductId: number;
}

export const getConfig = (cart: IProductForConfig[], country: string) => {
    return (dispatch: Dispatch<any>) => {
        fetchAPI_REST(routes.PAYMENT_CONFIG, {
            type: 'formation',
            cart,
            country,
            backUrl: getIokaLink('back'), 
            failUrl: getIokaLink('fail'), 
            successUrl: getIokaLink('success')
        })
        .then((resp: any) => {
            const newData = {
              ...resp,
              type: resp.data?.type, 
            };
            dispatch(getConfigSuccess(newData));
        })
        .catch(error => { dispatch(signOut(error)); });
    };
}

const getConfigSuccess = (data: any) => {
    if(data?.ioka?.success && data?.ioka?.url) {
        window.location.href = data.ioka.url;
        return removeConfig();
    }
    return {
        type: CONFIG_GET_SUCCESS,
        payload: data
    }
}

export const removeConfig = () => {
    return {
        type: CONFIG_REMOVE,
    }
}